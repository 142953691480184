import { format, formatRelative } from "date-fns";

import { DropdownItem } from "@/components/form/Dropdown/Dropdown.types";
import { VolunteerTrainingDay } from "@/types/forumTypes";

export function parseDateRelative(date: Date) {
  if (!date) return;
  return formatRelative(date, Date.now());
}

export function parseDateWithTime(date: string) {
  if (!date) return;
  return format(new Date(date), "dd-MM-yyy hh:mm:ss");
}

export function parseDate(date: Date) {
  if (!date) return;
  return format(date, "dd-MM-yyy");
}

export const formatVolunteerTrainingDaysList = (
  volunteerTrainingDays: VolunteerTrainingDay[],
): DropdownItem[] => {
  return volunteerTrainingDays.map((trainingDay) => {
    const dateObj = new Date(trainingDay.date);
    const formattedDate = `${dateObj.toLocaleDateString("nl-NL", {
      weekday: "long",
      day: "numeric",
      month: "long",
    })} om ${dateObj.toLocaleTimeString("nl-NL", {
      hour: "2-digit",
      minute: "2-digit",
    })} uur`;

    return {
      name: `${trainingDay.trainingTitle} op ${formattedDate}`,
      value: trainingDay.id,
    };
  });
};

export const getDropdownItemById = (
  volunteerTrainingDateId: string,
  volunteerTrainingDays: VolunteerTrainingDay[],
): DropdownItem | undefined => {
  // Format the list of volunteer training days
  const formattedList = formatVolunteerTrainingDaysList(volunteerTrainingDays);

  // Find the item with the matching ID
  return formattedList.find((item) => item.value === volunteerTrainingDateId);
};

export const calculateDaysLeft = (date: Date): string => {
  const newDate = new Date(date);

  // Set the time to midnight for both dates to count only full days
  newDate.setHours(0, 0, 0, 0);

  // Create a new date for 'now' and set its time to midnight
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds between the target date and now
  const timeDifference = newDate.getTime() - now.getTime();

  // Calculate the number of days left, rounding down to the nearest whole number
  const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return `over ${daysLeft} ${daysLeft === 1 ? "dag" : "dagen"}`;
};

export const formatEventDate = (startDate: Date, endDate: Date): string => {
  const optionsDate: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  const start = new Date(startDate);
  const end = new Date(endDate);

  const formattedDate = start.toLocaleDateString("nl-NL", optionsDate);
  const formattedStartTime = start.toLocaleTimeString("nl-NL", optionsTime);
  const formattedEndTime = end.toLocaleTimeString("nl-NL", optionsTime);

  return `${formattedDate} om ${formattedStartTime} - ${formattedEndTime}`;
};
